(function () {
  if (window._fitAnalytics) return;

  window._fitAnalytics = function (imports) {
    const { $, _, Integration } = imports;

    const buttonRightIcon = $('<img>')
        // .attr({ 'src': '//localhost:3018/icon.png' })
        .attr({ src: '//integrations.fitanalytics.com/shop/superbalist/icon.png' })
        .css({
          width: '20px',
          height: '20px',
          'margin-bottom': '5px',
          cursor: 'pointer',
          display: 'inline-block',
          'margin-left': '10px'
        }),
      baseConfig = {
        // debug: true,
        shop: {
          prefix: 'superbalist-',
          language: () => 'en',
          country: () => 'ZA',
          userId: {
            selector: '.content-wrapper',
            attr: 'data-user'
          },
          // shopSessionId: {
          //   cookie: '_gid'
          // }
        },

        globalEvents: {
          product_load: () => {
            $(`[data-fitanalytics="fitanalytics-anchor"]`).css({
              height: '42px',
              display: 'block'
            });
            // we need to append the icon element to our button:
            $('.fitanalytics__button').append(buttonRightIcon);

            $(`[data-fitanalytics="product-size-chart"]`)
              .addClass('fita-size-chart')
              .off('click.fita')
              .on('click.fita', function () {
                $(this).toggleClass('uncollapsed');
              });
          },
          product_error: () => {
            if (Integration.isMobile())
              $(`.pdp-side-top div[data-fitanalytics="fitanalytics-anchor"]`).css({
                'margin-bottom': '0px !important'
              });
            $(`div[data-fitanalytics="fitanalytics-anchor"]`).css({ height: '0' });
          }
        },

        scope: 'body',
        product: {
          container: '.page-product-detail',
          isLoaded: () => $(`.sku-list div[data-fitanalytics="variation-element"]`).length,

          hasManufacturedSizes: true,

          currentSerial: {
            selector: `[data-fitanalytics="product-serial"]`,
            attr: 'href',
            process: (href) => (href || '').split('/').pop()
          },

          thumbnail: {
            selector: `[data-fitanalytics="product-thumbnail"]`,
            attr: 'src'
          },

          addToCart: `[data-fitanalytics="add-to-cart"]`,

          sizes: {
            items: `[data-fitanalytics="variation-element"]`,
            code: {
              selector: '.',
              prop: 'text'
            },
            isAvailable: {
              selector: '.',
              is: ':not(.soldOut)'
            },
            isSelected: {
              selector: '.',
              is: '.selected'
            }
          }
        },

        button: {
          anchor: `[data-fitanalytics="fitanalytics-anchor"]`,
          anchorPlacement: 'append',
          text: {
            en: ['FIND YOUR FIT', 'YOUR SIZE - {{size}}']
          },
          style: {
            button: {
              margin: '0',
              float: 'right'
            },
            image: {
              width: '32px',
              height: '32px'
            },
            text: {
              'font-weight': '600',
              color: '#2e3131',
              'line-height': '2.6em'
            },
            ariaCSS: {
              outline: 'none'
            }
          },
          imageSrc: '//integrations.fitanalytics.com/shop/superbalist/FF_icon.jpeg'
          // imageSrc: '//localhost:3018/FF_icon.jpeg',
        },

        sizeChartButton: {
          elem: '.product-sizes-accordian .product-list-partial__content',
          listener: '.fita-size-chart.uncollapsed',
          shouldHide: false
        }
      },
      mobileConfig = {
        key: 'mobileConfig',
        sizeChartButton: {
          elem: `.pdp-side-bottom`
        }
      };

    const integration = Integration.isMobile()
      ? new Integration(baseConfig, [mobileConfig])
      : new Integration(baseConfig);

    window._fitAnalytics.integration = integration;
    integration.start();
  };

  function init() {
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', '//widget.fitanalytics.com/widget_pdp.js');
    scriptElem.setAttribute('type', 'text/javascript');
    document.body.appendChild(scriptElem);
  }

  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      init();
    });
  }
})();
